import { elasticClient } from "./client";
import {
  SearchData,
  SearchResult,
  SearchSuggestions,
  AnalyticsResults,
  SearchOptions,
  Search,
} from "./types";
import { SEGMENT_ALL_ID, SUGGESTION_SEARCH_SIZE } from "./constants";

type PopularSearches = AnalyticsResults & {
  networkError: boolean;
};

type GetSearchResult = {
  result: SearchData<SearchResult[]>;
} & {
  error: boolean;
};

export const w = typeof window !== "undefined" ? window : undefined;

export const getPopularSearchResult = async (): Promise<PopularSearches> => {
  try {
    const { results } = await elasticClient<AnalyticsResults>(
      "analytics/queries",
      undefined,
      "GET"
    );

    return {
      results,
      networkError: false,
    };
  } catch (e) {
    return {
      results: [],
      networkError: true,
    };
  }
};

export const elasticTrackClick = (query: string, document_id: string, requestId?: string): void => {
  elasticClient(
    "click",
    { query, document_id, ...(requestId && { request_id: requestId }) },
    "POST"
  );
};

export const getSearchResult = async ({
  query = "",
  record_analytics = true,
  category,
  page,
  segment,
}: Search): Promise<GetSearchResult> => {
  if (query.trim().length === 0) {
    return { result: { meta: null, facets: null, results: [] }, error: false };
  }

  const options: SearchOptions = {
    query,
    page: { current: page },
    record_analytics,
    filters: {
      all: [
        ...(segment && segment !== SEGMENT_ALL_ID ? [{ segment: [segment] }] : []),
        ...(category ? [{ category }] : []),
      ],
    },
  };

  try {
    const result = await elasticClient<SearchData<SearchResult[]>>("search", options);

    return {
      result,
      error: false,
    };
  } catch (e) {
    return {
      result: {
        meta: null,
        facets: null,
        results: [],
      },
      error: true,
    };
  }
};

export const getSearchSuggestionResult = async (
  query: string,
  resultSize = SUGGESTION_SEARCH_SIZE
) => {
  if (query.trim().length === 0) {
    return { result: [], error: false };
  }

  try {
    const data = await elasticClient<SearchData<SearchSuggestions>>("query_suggestion", {
      query,
    });

    return {
      result: data.results
        ? data.results.documents
            .filter(({ suggestion }) => !suggestion.includes('"'))
            .map(({ suggestion }) => suggestion)
            .slice(0, resultSize)
        : [],
      error: false,
    };
  } catch (e) {
    return {
      result: [],
      error: true,
    };
  }
};
