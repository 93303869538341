import { BASE_URL, RESULT_FACETS, RESULT_FIELDS } from "./constants";
import { ClickOptions, SearchOptions } from "./types";
import { fetchWithOptions } from "@telia/b2x-graphql-request";

export const elasticClient = async <T>(
  url: string,
  data?: SearchOptions | ClickOptions,
  method = "POST"
): Promise<T> => {
  const bodyData = url.includes("search")
    ? { ...data, facets: RESULT_FACETS, result_fields: RESULT_FIELDS }
    : data;

  const response = await fetchWithOptions(`${BASE_URL}/${url}`, {
    method,
    mode: "cors",
    cache: "no-cache",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    referrerPolicy: "no-referrer",
    body: method !== "GET" ? JSON.stringify(bodyData) : null,
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  const responseText = await response.text();
  return responseText.length ? JSON.parse(responseText) : null;
};
