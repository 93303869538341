import { useEffect, useState } from "react";
import throttle from "lodash.throttle";
import type { Search, SearchData, SearchResult } from "./types";

import { AnalyticsResult } from "./types";
import { getPopularSearchResult, getSearchResult, getSearchSuggestionResult } from "./search";
const EMPTY_SEARCH = {
  meta: null,
  facets: null,
  results: [],
};

export const useSearch = () => {
  const [searchError, setSearchError] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchData<SearchResult[]>>(EMPTY_SEARCH);
  const [popularSearches, setPopularSearches] = useState<AnalyticsResult[]>([]);
  const [searched, setSearched] = useState(false);
  const [requestId, setRequestId] = useState<string | undefined>(undefined);
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
  const [searchSuggestionError, setSearchSuggestionError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const { results } = await getPopularSearchResult();
      setPopularSearches(results);
    })();
  }, []);

  const search = async (options: Search) => {
    setIsLoading(true);
    setSearchResult(EMPTY_SEARCH);
    setSearchError(false);

    const { result, error } = await getSearchResult(options);
    setSearched(true);
    setSearchResult(result);
    setRequestId(result.meta?.request_id);
    setSearchError(error);
    setIsLoading(false);
    return result;
  };

  const suggest = throttle(async (query: string, resultSize?: number) => {
    const { result, error } = await getSearchSuggestionResult(query, resultSize);

    setSearchSuggestionError(error);
    setSearchSuggestions(result);
  }, 400);

  const reset = () => {
    setSearched(false);
  };

  return {
    popularSearches,
    requestId,
    reset,
    search,
    searched,
    searchError,
    isLoading,
    searchResult,
    searchSuggestionError,
    searchSuggestions,
    suggest,
  };
};
